import React from "react";
import { Redirect, Route } from "react-router-dom";
import GlobalStore from "../../stores/GlobalStore";
import { Routes } from "../../constants";
import { observer } from "mobx-react";
import { RouteProps } from "react-router";

class PrivateRoute extends React.PureComponent<RouteProps> {
  render() {
    const { children, ...rest } = this.props;
    return (
      <Route {...rest}>
        {GlobalStore.isLoggedIn ? children : <Redirect to={Routes.Login} />}
      </Route>
    );
  }
}

export default observer(PrivateRoute);
