export const credentials = {
  username: "clarou",
  password: "beaubrun",
};

export enum Routes {
  Home = "/",
  Login = "/login",
  Photos = "/photos",
  Reasons = "/reasons"
}
