import React from "react";
import Gallery, { RenderImageProps } from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { getRandomInt } from "../../../utils/rand";

const photoPath = `${process.env.PUBLIC_URL}/img/`;

const photos = new Array(57).fill(0).map((_, index) => {
  const photoName = `${index + 1}.jpeg`;
  return {
    src: photoPath + photoName,
    width: getRandomInt(2, 4),
    height: getRandomInt(2, 4),
  };
});

export default class PhotoAlbum extends React.PureComponent<
  {},
  { open: boolean; currentImage: number }
> {
  constructor(props: {}, context: any) {
    super(props, context);
    this.state = { open: false, currentImage: 0 };

    this.openLightBox = this.openLightBox.bind(this);
    this.closeLightBox = this.closeLightBox.bind(this);
  }

  openLightBox(event: React.MouseEvent, photos: { index: number }) {
    this.setState({
      open: true,
      currentImage: photos.index,
    });
  }

  closeLightBox() {
    this.setState({
      currentImage: 0,
      open: false,
    });
  }

  renderImage(props: RenderImageProps) {
    const handleClick = (event: any) => {
      // @ts-ignore
      props.onClick(event, { photo: props.photo, index: props.index });
    };
    return (
      <img
        key={props.photo.src}
        style={{
          margin: props.margin,
          cursor: "pointer",
          objectFit: "cover",
        }}
        alt=""
        height={props.photo.height}
        width={props.photo.width}
        src={props.photo.src + "?nf_resize=fit&h=400"}
        onClick={handleClick}
      />
    );
  }

  render() {
    return (
      <>
        <Gallery
          renderImage={this.renderImage}
          photos={photos}
          onClick={this.openLightBox}
        />
        <ModalGateway>
          {this.state.open ? (
            <Modal onClose={this.closeLightBox}>
              <Carousel
                styles={{
                  view: (base, state) => ({ ...base, height: "100vh" }),
                }}
                currentIndex={this.state.currentImage}
                views={photos.map((x) => ({
                  source: x.src,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </>
    );
  }
}
