import React from "react";
import { Button, Form, Nav, Navbar } from "react-bootstrap";
import heart from "../../assets/heart.svg";
import { Routes } from "../../constants";
import GlobalStore from "../../stores/GlobalStore";

// const client = twilio(
//   "AC74ca8104eb51a5f52c1b9ab85c3b9aed",
//   "2fe007e7189146633a45046c23ae9140"
// );

// async function sendSMS() {
//   const msg = await client.messages.create({
//     body: "Belle Blonde t'envoie un gros calin 🥰",
//     from: "+15874044547",
//     to: "+14389297521",
//   });
//   console.log(console.log(msg.sid));
// }

export default class NavigationBar extends React.PureComponent {
  onDisconnectPressed() {
    GlobalStore.setAuthenticated(false);
  }

  render() {
    return (
      <>
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand href="/">
            <img
              alt=""
              src={heart}
              width="30"
              height="30"
              style={{ marginRight: 12 }}
              className="d-inline-block align-top"
            />
            Belle Blonde
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href={Routes.Home}>Accueil</Nav.Link>
              <Nav.Link href={Routes.Photos}>Photos</Nav.Link>
              <Nav.Link href={Routes.Reasons}>100 Raisons</Nav.Link>
              {/*<Nav.Link onClick={() => {}}>Calin pour Beau Brun</Nav.Link>*/}
            </Nav>
            <Form inline>
              <Button
                onClick={this.onDisconnectPressed}
                variant="outline-secondary"
              >
                Deconnexion
              </Button>
            </Form>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}
