import React from "react";
import { getRandomInt } from "../../../utils/rand";

export default class Reasons extends React.PureComponent {
  private reasons: string[] = [
    "tu me supportes meme quand je suis de mauvaise humeur.",
    "tu arrives a me faire rire meme quand je suis de mauvaise humeur.",
    "tu essayes de ne pas faire trop de bruit le matin, pour ne pas me reveiller.",
    "tu m'eccoutes et tu essayes toujours de m'aider lorsque j'ai des problemes.",
    "tu me cuisine des pancakes le matin.",
    "tu me cuisine tout un tas de bons trucs!",
    "tu fais les meilleurs massages du monde.",
    "m'endormir et me reveiller contre toi vaut de l'or.",
    "je ne me lasse pas de te regarder dormir comme un bebe.",
    "quand je suis avec toi, je n'ai besoin de rien d'autre.",
    "j'ai envie de passer tout mon temps avec toi.",
    "tu remarques quand je suis stresse ou de mauvaise humeur.",
    "tu me fais plein de calins.",
    "tu as un petit chat, comme moi.",
    "tu aimes les animeaux.",
    "tu as de l'empathie.",
    "j'adore ce qu'on fait au lit 🤤.",
    "je sais que je peux de faire confiance.",
    "on est tous les deux des gourmands.",
    "tu me fais rire a ne pas aimer la cannelle.",
    "tu m'as suivi sur un toit lors de notre premier rancard",
    "je ne vois pas le temps passer quand je suis avec toi.",
    "tu m'as suivi sur le toit d'une gare.",
    "tu es la plus belle.",
    "tu as le plus beau sourire.",
    "tu es courageuse.",
    "tu as plein d'energie.",
    "tu es sexy.",
    "tu fais de ton mieux pour me rendre la vie plus facile.",
    "tu cherches a savoir comment je me sens, tout le temps.",
    "tu as essaye de bien t'entendre avec mes amis.",
    "tu m'encourages a faire du sport",
    "tu m'encourages a aller faire du velo pendant de longues heures.",
    "tu aimes la nature et l'aventure, comme moi.",
    "on se complete.",
    "tu as achete Poupoule.",
    "tu m'encourages a prendre soin de moi.",
    "tu prends soin de toi.",
    "les dessins que tu me fais sont splendides."
  ];
  private colors: string[] = [
    "#FF6347",
    "#8D021F",
    "#B80F0A",
    "#BF0A30",
    "#D21F3C",
    "#B43757",
    "#EA3C53",
  ];

  render() {
    const slide = this.reasons[getRandomInt(0, this.reasons.length)];
    const color = this.colors[getRandomInt(0, this.colors.length)];

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          backgroundColor: color,
          height: "100vh",
        }}
      >
        <h1 style={{ color: "rgba(255,255,255,0.9)",}}>Je t'aime parce que</h1>
        <h2 style={{ color: "rgba(255,255,255,0.8)",}}>{slide}</h2>
      </div>
    );
  }
}
