import React, { ChangeEvent } from "react";
import { History, Location } from "history";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { credentials, Routes } from "../../../constants";
import GlobalStore from "../../../stores/GlobalStore";
import { withRouter } from "react-router-dom";
import { getRandomGif } from "../../../utils/loadGIFS";

interface LoginState {
  username: string;
  password: string;
  showAlert: boolean;
}

interface LoginProps {
  match: any;
  location: Location;
  history: History;
}

class Login extends React.PureComponent<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      username: "",
      password: "",
      showAlert: false,
    };
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);
  }

  onChangeUsername(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      username: event.target.value,
    });
  }

  onChangePassword(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      password: event.target.value,
    });
  }

  onSubmit() {
    const { password, username } = this.state;
    if (
      password === credentials.password &&
      username === credentials.username
    ) {
      GlobalStore.setAuthenticated(true);
      this.props.history.replace(Routes.Home);
    } else {
      this.setState({
        showAlert: true,
        password: "",
        username: "",
      });
    }
  }

  onCloseAlert() {
    if (this.state.showAlert) {
      this.setState({
        showAlert: false,
      });
    }
  }

  render() {
    return (
      <Container>
        <Row style={styles.row}>
          <Col style={{textAlign: "center"}}  md={6} sm={12}>
              <img src={getRandomGif()} className="App-logo" alt="logo" />
            <h1 style={styles.spaceOutTop}>Bienvenue sur BelleBlonde.ca!</h1>
            <h3 style={styles.spaceOutTop}>
              L'accès à ce site est protegé. Veuillez entrer vos identifiants.
            </h3>
            <Form style={styles.spaceOutTop} onSubmit={this.onSubmit}>
              <Form.Group controlId="email">
                <Form.Label>Nom d'utilisateur</Form.Label>
                <Form.Control
                  value={this.state.username}
                  onChange={this.onChangeUsername}
                  placeholder="Nom d'utilisateur"
                />
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Mot de Passe</Form.Label>
                <Form.Control
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  type="password"
                  placeholder="Mot de passe"
                />
              </Form.Group>
              <Button onClick={this.onSubmit} variant="primary">
                Confirmer
              </Button>
              {this.state.showAlert ? (
                <div style={{ marginTop: 24 }}>
                  <Alert
                    dismissible
                    onClose={this.onCloseAlert}
                    variant="danger"
                  >
                    Mauvais nom d'utilisateur ou mot de passe. Veuillez
                    réessayer.
                  </Alert>
                </div>
              ) : null}
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const styles = {
  spaceOutTop: {
    marginTop: 40,
  },
  row: { marginTop: 12, justifyContent: "center" },
};

export default withRouter(Login);
