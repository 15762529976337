import React from "react";
import "./App.scss";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Home from "./ui/screens/Home/Home";
import Login from "./ui/screens/Login/Login";
import PrivateRoute from "./ui/components/PrivateRoute";
import { Routes } from "./constants";
import GlobalStore from "./stores/GlobalStore";
import NavigationBar from "./ui/components/NavigationBar";
import PhotoAlbum from "./ui/screens/PhotoAlbum/PhotoAlbum";
import { observer } from "mobx-react";
import Reasons from "./ui/screens/Reasons/Reasons";

function App() {
  return (
    <div className="App">
      {GlobalStore.isLoggedIn ? <NavigationBar /> : null}
      <Router>
        <Switch>
          <PrivateRoute exact path={Routes.Home}>
            <Home />
          </PrivateRoute>
          <Route path={Routes.Login}>
            <Login />
          </Route>
          <Route path={Routes.Photos}>
            <PhotoAlbum />
          </Route>
          <Route path={Routes.Reasons}>
            <Reasons />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
    </div>
  );
}

export default observer(App);
