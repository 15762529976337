import { makeAutoObservable } from "mobx";
import * as ls from "local-storage";

class GlobalStore {
  isLoggedIn: boolean = ls.get("isLoggedIn");

  constructor() {
    makeAutoObservable(this);
  }

  setAuthenticated(value: boolean) {
    this.isLoggedIn = value;
    ls.set("isLoggedIn", value);
  }
}

export default new GlobalStore();
