import bird from "../assets/gifs/bird.gif";
import birdy from "../assets/gifs/birdy.gif";
import bunny from "../assets/gifs/bunny.gif";
import hug from "../assets/gifs/hug.gif";
import loof from "../assets/gifs/loof_ya.gif";
import nyan from "../assets/gifs/nyan.gif";
import support from "../assets/gifs/support.gif";
import pinguin from "../assets/gifs/pinguin.gif";
import melt from "../assets/gifs/melt.gif";
import cuddle from "../assets/gifs/cuddle.webp";
import { getRandomInt } from "./rand";

const GIFS = [bird, birdy, bunny, hug, loof, nyan, support, pinguin, melt, cuddle];

export function getRandomGif() {
  const randIndex = getRandomInt(0, GIFS.length);
  return GIFS[randIndex];
}
