import React from "react";
import { Jumbotron } from "react-bootstrap";

export default class Home extends React.PureComponent {
  render() {
    return (
      <Jumbotron style={{alignItems: "flex-start"}}>
          <h1>Belle Blonde,</h1>
          <p>
              Donc voici ton deuxieme cadeau, qui au final m'a pris plus de temps que prevu haha! <br/>
              Tu m'as dit que tu voulais un cadeau fait avec mes propres mains, et vu mon talent pour la poterie je me suis
              dit qu'il fallait mieux que je fasse un petit site web.
              <br/><br/>
              Il n'y a pas grande chose pour l'instant, mais ca serait cool de l'entretenir et ajouter des choses petit à petit.
              Ca pourrait etre un bon moyen de se faire un petit album photo 🥰.
              <br/><br/>
              J'espere que ce petit cadeau te fera plaisir.
              <br/><br/>
              Je t'aime fort,
              <br/><br/>
              Beau Brun.
          </p>
      </Jumbotron>
    );
  }
}
